import Vue from 'vue';

async function getContactInfo(userId) {
  return (await Vue.$api.getUserContactInfo(userId)).data;
}

async function setContactInfo(userId, contactInfo) {
  await Vue.$api.setUserContactInfo(userId, contactInfo);
}

const actions = {
  getContactInfo: 'getContactInfo',
  getUserProfile: 'getUserProfile',
  setContactInfo: 'setContactInfo',
};

const mutations = {
  saveUserProfile: 'saveUserProfile',
};

const userModule = {
  state: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    contactTime: null,
    candidatureDashboard: {},
    experiences: [],
    delayInMonth: null,
  },
  actions: {
    [actions.getContactInfo]: async ({commit}, {userId}) => {
      const {contactInformation} = await getContactInfo(userId);
      commit(mutations.saveUserProfile, contactInformation);
      return contactInformation;
    },
    [actions.setContactInfo]: async ({commit}, contactInfo) => {
      const {userId, ...data} = contactInfo;
      await setContactInfo(userId, data);
      commit(mutations.saveUserProfile, contactInfo);
    },
  },
  mutations: {
    [mutations.saveUserProfile]: (state, userProfile) => {
      Object.assign(state, {...state, ...userProfile});
    },
  },
};

export { userModule as default, actions };
